export const discoverItems = [
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Product",
    profilePic: "./images/users/user-three.jpg",
    profileAlt: "User Profile Picture",
    idUrl: "#",
    comments: [
      {
        name: "Abdul Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Jawad Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-two.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Abdulah khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-four.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        heading: "Jahangir Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Sarim Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
    ],
  },
  {
    productImage: "./images/products-images/watch-image.jpg",
    imageAlt: "Watch Product",
    profilePic: "./images/users/user-two.jpg",
    profileAlt: "User Profile Picture",
    idUrl: "#",
    comments: [
      {
        name: "Abdul Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Jawad Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-two.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Abdulah khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-four.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        heading: "Jahangir Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Sarim Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
    ],
  },
  {
    productImage: "./images/products-images/speaker-image.jpg",
    imageAlt: "Speaker Product",
    profilePic: "./images/users/user-four.jpg",
    profileAlt: "User Profile Picture",
    idUrl: "#",
    comments: [
      {
        name: "Abdul Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Jawad Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-two.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Abdulah khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-four.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        heading: "Jahangir Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Sarim Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
    ],
  },
  {
    productImage: "./images/products-images/cream-image.jpg",
    imageAlt: "Cream Product",
    profilePic: "./images/users/user-three.jpg",
    profileAlt: "User Profile Picture",
    idUrl: "#",
    comments: [
      {
        name: "Abdul Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Jawad Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-two.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Abdulah khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-four.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        heading: "Jahangir Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Sarim Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
    ],
  },
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Product",
    profilePic: "./images/users/user-three.jpg",
    profileAlt: "User Profile Picture",
    idUrl: "#",
    comments: [
      {
        name: "Abdul Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Jawad Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-two.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Abdulah khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-four.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        heading: "Jahangir Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Sarim Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
    ],
  },
  {
    productImage: "./images/products-images/cream-image.jpg",
    imageAlt: "Camera Product",
    profilePic: "./images/users/user-three.jpg",
    profileAlt: "User Profile Picture",
    idUrl: "#",
    comments: [
      {
        name: "Abdul Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Jawad Rehman",
        text: "I wonder  could buy this product",
        image: "./images/users/user-two.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Abdulah khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-four.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        heading: "Jahangir Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
      {
        name: "Sarim Khan",
        text: "I wonder  could buy this product",
        image: "./images/users/user-one.jpg",
        imageAlt: "Commenters Profile Picture",
      },
    ],
  },
];

export const followingProfiles = [
  {
    image: "./images/users/user-one.jpg",
    imageAlt: "User Profile Image",
    url: "/user-profile",
    heading: "Jahangir Khan",
  },
  {
    image: "./images/users/user-two.jpg",
    imageAlt: "User Profile Image",
    url: "/user-profile",
    heading: "Haris Khan",
  },
  {
    image: "./images/users/user-four.jpg",
    imageAlt: "User Profile Image",
    url: "/user-profile",
    heading: "Sarim Khan",
  },
  {
    image: "./images/users/user-three.jpg",
    imageAlt: "User Profile Image",
    url: "/user-profile",
    heading: "Nadeem Khan",
  },
  {
    image: "./images/users/user-one.jpg",
    imageAlt: "User Profile Image",
    url: "/user-profile",
    heading: "Hassan Khan",
  },
  {
    image: "./images/users/user-four.jpg",
    imageAlt: "User Profile Image",
    url: "/user-profile",
    heading: "Sarim Khan",
  },
  {
    image: "./images/users/user-three.jpg",
    imageAlt: "User Profile Image",
    url: "/user-profile",
    heading: "Nadeem Khan",
  },
  {
    image: "./images/users/user-one.jpg",
    imageAlt: "User Profile Image",
    url: "/user-profile",
    heading: "Hassan Khan",
  },
];

export const profiles = [
  {
    image: "./images/users/user-one.jpg",
    heading: "Jahangir Khan",
    subHeading: "Pakistan",
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-two.jpg",
    profileAlt: "Uploader Image",
    comments: [
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
    ],
  },
  {
    image: "./images/users/user-one.jpg",
    heading: "Jahangir Khan",
    subHeading: "Pakistan",
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-two.jpg",
    profileAlt: "Uploader Image",
    comments: [
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
    ],
  },
  {
    image: "./images/users/user-one.jpg",
    heading: "Jahangir Khan",
    subHeading: "Pakistan",
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-two.jpg",
    profileAlt: "Uploader Image",
    comments: [
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
    ],
  },
  {
    image: "./images/users/user-one.jpg",
    heading: "Jahangir Khan",
    subHeading: "Pakistan",
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-two.jpg",
    profileAlt: "Uploader Image",
    comments: [
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
    ],
  },
  {
    image: "./images/users/user-one.jpg",
    heading: "Jahangir Khan",
    subHeading: "Pakistan",
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-two.jpg",
    profileAlt: "Uploader Image",
    comments: [
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
    ],
  },
  {
    image: "./images/users/user-one.jpg",
    heading: "Jahangir Khan",
    subHeading: "Pakistan",
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-two.jpg",
    profileAlt: "Uploader Image",
    comments: [
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
      {
        name: "Sarim Bay",
        text: "Please Place Order For Me.",
        image: "./images/users/user-three.jpg",
        imageAlt: "Profile Picture",
      },
    ],
  },
];
