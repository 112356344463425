export const liveChannels = [
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-four.jpg",
    profileAlt: "User Image",
    idUrl: "#",
    comments: [
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
    ],
  },
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-four.jpg",
    profileAlt: "User Image",
    idUrl: "#",
    comments: [
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
    ],
  },
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-four.jpg",
    profileAlt: "User Image",
    idUrl: "#",
    comments: [
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
    ],
  },
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-four.jpg",
    profileAlt: "User Image",
    idUrl: "#",
    comments: [
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
    ],
  },
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-four.jpg",
    profileAlt: "User Image",
    idUrl: "#",
    comments: [
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
    ],
  },
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-four.jpg",
    profileAlt: "User Image",
    idUrl: "#",
    comments: [
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
    ],
  },
  {
    productImage: "./images/products-images/camera-image.jpg",
    imageAlt: "Camera Image",
    profilePic: "./images/users/user-four.jpg",
    profileAlt: "User Image",
    idUrl: "#",
    comments: [
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Jahangir Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-one.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Nadeem Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-two.jpg",
        imageAlt: "User Profile",
      },
      {
        name: "Hassan Khan",
        text: "Amzaing Product Keep it Up",
        image: "./images/users/user-three.jpg",
        imageAlt: "User Profile",
      },
    ],
  },
];
